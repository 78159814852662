import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";

import SiteWrapper from "../../base/SiteWrapper";

import { useHistory, useParams } from 'react-router-dom';

import { Editor } from '@tinymce/tinymce-react';
import api from '../../services/api';

import {
  Page,
  Card,
  Button,
  Form,
  Icon
} from "tabler-react";
import NotificationTypeStore from "../../interfaces/NotificationTypeStore";
import NotificationType from "../../interfaces/NotificationType";

interface Variable {
  code: string;
  value: string;
}

const NotificationsForm = () => {

  const history = useHistory();
  const { id } = useParams();

  const initialValues: NotificationTypeStore = {
    id: "",
    subject: "",
    body: "",
    options: {},
    notificationType: {
      id: "",
      name: ""
    }
  };

  const [notificationType, setNotificationType] = useState<NotificationTypeStore>(initialValues);
  const [defaultTypes, setDefaultTypes] = useState([]);
  const [variablesCollapsed, setVariablesCollapsed] = useState(true);

  useEffect(() => {
    api.get('notificationtypes').then(response => {
      setDefaultTypes(response.data);
      setNotificationType({...notificationType, notificationType: response.data[0]});
    });
    if(!!id) {
      api.get(`notificationtypestores/${id}`).then(response => {
        setNotificationType(response.data);
      });
    }
  }, [id]);

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setNotificationType({...notificationType, [name]: value});
  }

  function handleChangeOptions(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const options = {...notificationType.options, [name]: value}
    setNotificationType({...notificationType, options});
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    const newNotificationType = {
      id: parseInt(value)
    } as NotificationType;
    setNotificationType({...notificationType, notificationType: newNotificationType});
  }

  const handleEditorChange = (content: any, editor: any) => {
    setNotificationType({...notificationType, body: content});
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        if (notificationType.notificationType.id == 4
         && (!notificationType.options || !notificationType.options.last_transaction_days)) {
          alert('É necessário informar o campo "Dias após Última Transação"!');
          return;
        }

        if (notificationType.notificationType.id == 2
         && (!notificationType.options || !notificationType.options.expiration_days)) {
          alert('É necessário informar o campo "Dias para Expiração"!');
          return;
        }

        if (notificationType.notificationType.id == 6
         && (!notificationType.options || !notificationType.options.days)) {
          alert('É necessário informar o campo "Quantidade de dias após gerar o cupom para notificar"!');
          return;
        }

        const data = {
          "subject": notificationType.subject,
          "body": notificationType.body,
          "notification_type_id": notificationType.notificationType.id,
          "options": notificationType.options
        }

        if(!!notificationType.id) {
          await api.put(`notificationtypestores/${notificationType.id}`, data)
          alert('Tipo de notificação atualizado!');
        } else {
          await api.post('notificationtypestores', data)
          alert('Tipo de notificação incluído!');
        }

        history.push('/notificationtypes');
      } catch (err) {
        if(!!notificationType.id) {
          alert('Erro ao atualizar o tipo de notificação!');
        } else {
          alert('Erro ao cadastrar o tipo de notificação!');
        }
      }
    }, [history, notificationType]
  );

  const getAvailableVariables = useCallback(
    (): Variable[] => {
      let variables = [
        { code: 'nome', value: 'Nome do cliente'},
        { code: 'sobreNome', value: 'Sobrenome do cliente'},
        { code: 'loja', value: 'Nome da loja'},
        { code: 'urlLoja', value: 'URL da loja'},
        { code: 'saldo', value: 'Saldo de Pontos do Cliente'},
        { code: 'nivel', value: 'Nível do Cliente'},
        { code: 'nomePrograma', value: 'Nome do programa de fidelidade'},
        { code: 'nomePontos', value: 'Nome dos pontos do programa de fidelidade'},
      ]
      switch(notificationType.notificationType.id) {
        case 1:
        case 3:
          variables.push({code: 'pontos', value: 'Pontos da transação'})
          break;
        case 2:
          variables.push({code: 'dias', value: ' Dias para expirar os pontos'})
          break;
        case 4:
          variables.push({code: 'dias', value: ' Dias após a última transação'})
          variables.push({code: 'recompensas', value: ' Lista das recompensas disponíveis'})
          break;
        case 6:
          variables.push({code: 'dias', value: ' Quantidade de dias em que o usuário recuperou os pontos'})
          variables.push({code: 'cupom', value: 'Código do cupom de desconto'})
          break;
        case 7:
          variables.push({code: 'pontos', value: 'Pontos da indicação'})
          break;
      }
      return variables;
    }, [notificationType]
  )

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Notificação"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Tipo">
              <Form.Select
                value={notificationType.notificationType.id}
                onChange={handleSelectChange}
                name="type"
                disabled={!!id}
              >
                { defaultTypes.map((defaultNotificationType: NotificationType) => {
                  return (
                    <option
                      key={defaultNotificationType.id}
                      value={defaultNotificationType.id}
                    >
                      { defaultNotificationType.name }
                    </option>
                )}) }
              </Form.Select>
            </Form.Group>
            {(notificationType.notificationType.id === 2 && !!notificationType.options) &&
              <Form.Group label="Dias para Expiração">
                <Form.Input
                  name="expiration_days"
                  value={notificationType.options.expiration_days || ""}
                  onChange={handleChangeOptions}
                />
              </Form.Group>
            }
            {(notificationType.notificationType.id === 4 && !!notificationType.options) &&
              <Form.Group label="Dias após Última Transação">
                <Form.Input
                  name="last_transaction_days"
                  value={notificationType.options.last_transaction_days || ""}
                  onChange={handleChangeOptions}
                />
              </Form.Group>
            }
            {(notificationType.notificationType.id === 6 && !!notificationType.options) &&
              <Form.Group label="Quantidade de dias após gerar o cupom para notificar">
                <Form.Input
                  name="days"
                  value={notificationType.options.days || ""}
                  onChange={handleChangeOptions}
                />
              </Form.Group>
            }
            <Form.Group label="Variáveis">
              <Form.StaticText>
                Você pode utilizar variáveis para customizar o título e corpo das notificações. <br />
                Exemplo: "Olá {`{{nome}}`} hoje é seu aniversário e você ganhou {`{{pontos}}`} pontos" <br /><br />
                <b>Ver variáveis disponíveis para essa notificação:</b><Icon name={variablesCollapsed ? 'chevron-up' : 'chevron-down'} onClick={() => {setVariablesCollapsed(!variablesCollapsed) }} style={{marginLeft:'5px'}}/>
              </Form.StaticText>
              <Form.StaticText hidden={variablesCollapsed}>
                <ul>
                  {
                    getAvailableVariables().map((item) => {
                      return <li>{item.code}: {item.value}</li>
                    })
                  }
                </ul>
              </Form.StaticText>
            </Form.Group>
            <Form.Group label="Título">
              <Form.Input
                name="subject"
                value={notificationType.subject || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Corpo">
              <Editor
                initialValue=""
                value={notificationType.body}
                apiKey="fe2r2u5btwc8mm9pub3mgvsgui6rrdrmxof0a2prb2oehymd"
                init={{
                  height: 500,
                  menubar: false,
                  language: "pt_BR",
                  language_url: "/langs/pt_BR.js",
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | code | removeformat | \
                    link image'
                }}
                onEditorChange={handleEditorChange}
            />
          </Form.Group>
        </Form.FieldSet>
      </Page.Card>
    </Form>
  </SiteWrapper>)
}

export default NotificationsForm;